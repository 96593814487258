import '../styles/FAQ.scss'
import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React, { useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import SEO, { getSeoMedia } from '../atoms/SEO'
import WriteUs from '../components/WriteUs'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { QuestionsList } from '../molecules/QuestionsList'
import { questionListFAQ } from '../molecules/QuestionsList/questionListFAQ'
import { selectDeviceSize } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const jsonLdQuestionListData = questionListFAQ.map(({ ask, ans }) => ({
  question: ask,
  answer: ReactDOMServer.renderToStaticMarkup(ans())
}))

const FAQ = () => {
  let root
  const isDocument = () => typeof document !== 'undefined'

  useEffect(() => {
    root = document.querySelector('.root')
  }, [])

  useEffect(() => {
    if (isDocument()) {
      root.scrollTo(0, 0)
      root.classList.remove('root_fixed')
    }
  }, [root])

  const wWidth = useSelector(selectDeviceSize)

  return (
    <>
      <App>
        <SEO
          children={
            <>
              <BreadcrumbSEO />
              <FAQJsonLd questions={jsonLdQuestionListData} />
            </>
          }
          description="Ответы на часто задаваемые вопросы о психологических онлайн-консультациях в YouTalk →Онлайн-сервис психологической помощи YouTalk"
          images={getSeoMedia([{ pathname: '/img/og/faq.png', alt: 'faq' }])}
          title="Ответы на часто задаваемые вопросы о психологических онлайн-консультациях в YouTalk"
        />

        <Header />
        <BreadCrumbs />
        <main className="main">
          <section className="questions">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12 col-lg-10">
                  <h1 className="questions__title">FAQ</h1>
                  <ul className="questions__list questions-list">
                    <QuestionsList page="FAQ" />
                  </ul>
                </div>
              </div>
            </div>

            {wWidth > 1439 && (
              <>
                <img
                  alt="Светло-красный полукруг"
                  className="questions__decor"
                  src="/img/icons/faq/decor.svg"
                />
                <img
                  alt="Синий полукруг"
                  className="questions__decor2"
                  src="/img/icons/faq/decor2.svg"
                />
              </>
            )}
          </section>

          <WriteUs page="faq" />
        </main>

        <Footer />
      </App>
    </>
  )
}

export default FAQ
